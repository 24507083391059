import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Toast,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInput from "../common/CustomInput";
import customAxios from "../../customAxios";
import { toast } from "react-toastify";
import SelectInput from "../common/SelectInput";
import ImagesInput from "../common/ImagesInput";
import { useDispatch, useSelector } from "react-redux";
import { MasterListSearch } from "../../redux/actions/SettingActions";
import { AddPostCode as addPostCode } from "../../redux/actions/SettingActions";
import { RESET_ERROR } from "../../redux/constants/userConstants";

const AddPostCode = ({ openModal, onCloseModal, title, data }) => {
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState();
  const [stores, setStores] = useState([]);
  const [image, setImage] = useState("");
  const [selectedValue, setSelectedValue] = useState();

  const { master_post_code_search, post_code_added, post_code_add_err } =
    useSelector((state) => state?.settings);

  const dispatch = useDispatch();

  const schema = yup.object({
    post_codes: yup
      .array()
      .required("Post code required")
      .typeError("Post code required"),
  });

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const clearclose = () => {
    onCloseModal();
    reset();
    setSelectedValue([]);
  };

  useEffect(() => {
    if (post_code_added) {
      toast.success("Post code added");
      reset({});
      onCloseModal();
      setSelectedValue([]);


      dispatch({
        type: RESET_ERROR,
      });
    } else if (post_code_add_err) {
      console.log({ post_code_add_err });

      toast.error(post_code_add_err?.message || 'Something went wrong');
      // reset({});
      // onCloseModal();
    }
  }, [post_code_added, post_code_add_err]);

  useEffect(() => {
    if (Array.isArray(master_post_code_search)) {
      setStores(
        master_post_code_search.flat()?.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }))
      );
    }
  }, [master_post_code_search]);

  const onInputChange = useCallback((event) => {
    if (event) {
      dispatch(MasterListSearch(event));
    }
  }, []);

  const createRider = async (datas) => {
    const value = selectedValue?.map(({ value, label }) => ({
      id: value,
      name: label,
    }));
    dispatch(addPostCode(value));
  };

  return (
    <Modal isOpen={openModal} toggle={clearclose} size="md">
      <ModalHeader toggle={clearclose}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          {"Add post code"}
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Col>
            <SelectInput
              fieldLabel="Post Codes"
              selectvalue={selectedValue}
              isMultiple
              placeholder={'Search here...'}
              onInputChange={onInputChange}
              onChangeValue={(item) => {
                setSelectedValue(item);
                setValue("post_codes", item);
                setError("post_codes", { type: "custom", message: null });
              }}
              control={control}
              error={errors.post_codes}
              fieldName="post_codes"
              options={stores}
            />
          </Col>

          {/* <span style={{ color: "red" }}>Image resolution should be 768px * 1000px</span> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          type="button"
          color="primary"
          onClick={handleSubmit(createRider)}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
        <Button
          type="button"
          color="secondary"
          onClick={() => {
            onCloseModal();
            reset();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddPostCode;
