
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DataTables from "../common/DataTables";
import { useDispatch, useSelector } from "react-redux";
import { GetCustomerList } from "../../redux/actions/SettingActions";

const CustomerList = () => {
    const dispatch =useDispatch()

    const { CustomerListdata } = useSelector(state => state.settings)
    // console.log(CustomerListdata,"CustomerListdata")

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Name")}</b>,
            header: <b>{Capitalize("Name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Email")}</b>,
            header: <b>{Capitalize("Email")}</b>,
            selector: row => row?.email,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Address")}</b>,
            header: <b>{Capitalize("Address")}</b>,
            selector: (row) => (
                <>
                    {row?.address?.area?.user_address || row?.address?.area?.address ? (<span>
                         {row?.address?.area?.user_address ? row?.address?.area?.user_address+row?.address?.area?.address  : row?.address?.area?.address},
                    </span>) : "-"}
                </>

            ),
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Mobile (+44)")}</b>,
            header: <b>{Capitalize("Mobile (+44)")}</b>,
            selector: row => row?.mobile,
            style: {
                textAlign: "center",
            },
        },
   
        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
       
    ];

useEffect(() => {
 dispatch(GetCustomerList())
}, [])

  return (
    <Fragment>
            <Breadcrumb title="Customer" parent="Products" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Customer List</h5>
                            </CardHeader>
                            <CardBody>
                                
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {CustomerListdata && <DataTables
                                        columns={columns}
                                        row={CustomerListdata ? CustomerListdata : []}
                                    />}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
           
        </Fragment>
  )
}

export default CustomerList